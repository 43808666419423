import React, { Component } from "react";

// services
import DonationManagementService from "../services/green4/DonationManagementService";
import RoutingService from "../services/RoutingService";
import TicketingService from "../services/green4/TicketingService";

// js
import { getUrlForPageComponent } from "../js/routing";
import { addProductsToBooking } from "./booking/productFunctions";
import { convertDateToUnix } from "../components/date";

const ticketingEnabled = window.ticketingEnabled
  ? window.ticketingEnabled === "true"
  : false;

export const TicketingContext = React.createContext({
  booking: null,
  bookingLoaded: false,
  readBooking: () => {},
  bookingExpiry: null,
  bookingExpiryLoaded: false,
  readBookingExpiry: () => {},
  channel: null,
  channelLoaded: false,
  readChannel: () => {},
  resetTicketingState: () => {},
  additionalProductPageGroups: null,
  setBookingJourneyStartPage: () => {},
  setAdditionalProductPageGroups: () => {},
  updateAdditionalProductPageGroupsInitialQuantity: () => {},
  bookingQuestions: null,
  giftAidResult: null,
  getNextPage: () => {},
  deliveryProductVariants: null,
  clearDeliveryProductVariants: () => {},
});

export class TicketingProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      booking: null,
      bookingLoaded: false,
      bookingExpiry: null,
      bookingExpiryLoaded: false,
      channel: null,
      channelLoaded: false,
      additionalProductPageGroups:
        localStorage.getItem("additionalProductPageGroups") !== null
          ? JSON.parse(
              localStorage.getItem("additionalProductPageGroups")
            )
          : [],
      bookingQuestions: null,
      giftAidResult: null,
    };
  }

  readBooking = () => {
    // check if ticketing enabled
    if (!ticketingEnabled) return;
    TicketingService.readBooking().then((response) => {
      this.setState({ booking: response.data, bookingLoaded: true });
    });
  };

  setBooking = (booking) => {
    this.setState({ booking: booking, bookingLoaded: true });
  };

  readBookingExpiry = () => {
    // check if ticketing enabled
    if (!ticketingEnabled) return;
    TicketingService.readBookingExpiry().then((response) => {
      this.setState({
        bookingExpiry: response.data,
        bookingExpiryLoaded: true,
      });
    });
  };

  setBookingExpiry = (bookingExpiry) => {
    this.setState({
      bookingExpiry: bookingExpiry,
      bookingExpiryLoaded: true,
    });
  };

  setBookingJourneyStartPage = () => {
    localStorage.setItem(
      "bookingJourneyStartPage",
      window.location.pathname + window.location.search
    );
  };

  readChannel = () => {
    // check if ticketing enabled
    if (!ticketingEnabled) return;
    TicketingService.channel().then((response) => {
      this.setState({ channel: response.data, channelLoaded: true });
    });
  };

  resetTicketingState = () => {
    this.setState({
      booking: null,
      bookingLoaded: false,
      bookingExpiry: null,
      bookingExpiryLoaded: false,
      channel: null,
      channelLoaded: false,
    });
  };

  getValidAdditionalProductPageGroups = (
    additionalProductPageGroups,
    additionalProductsForAvailabilityCheck,
    start,
    bookingProductIds,
    validateAdditionalProductAvailability
  ) => {
    const { setAdditionalProductPageGroups } = this;

    return new Promise(function (resolve) {
      // if there are no additional product page groups, or the booking products added are not dated, then just return the additional product page groups
      if (
        !additionalProductsForAvailabilityCheck ||
        additionalProductsForAvailabilityCheck.length === 0
      ) {
        // set all additional product page groups as valid
        setAdditionalProductPageGroups(additionalProductPageGroups);

        resolve(additionalProductPageGroups);
      } else {
        TicketingService.getAvailableProducts(
          additionalProductsForAvailabilityCheck,
          convertDateToUnix(start),
          bookingProductIds
        ).then((response) => {
          const validProductIds = response.data;

          function isValidProduct(product) {
            // check if the product is included in the valid products
            if (validProductIds.includes(product.Id)) {
              return true;
            }
            // check the product has product variants
            if (!product.ProductVariants) {
              return false;
            }
            // check if any product variants were checked for availability
            const productVariantIds = product.ProductVariants.map(
              (v) => {
                return v.Id;
              }
            );
            if (
              additionalProductsForAvailabilityCheck.some((v) =>
                productVariantIds.includes(v)
              )
            ) {
              // product was checked for availability but not included in validProductIds
              return false;
            }
            // product was not checked for availability because it is not a dated product
            return true;
          }

          function isValidProductGroup(productGroup) {
            // check if the product group has products
            return (
              productGroup.Products &&
              productGroup.Products.length > 0
            );
          }

          function isValidPageGroup(pageGroup) {
            // check if the page group has products
            if (pageGroup.Products && pageGroup.Products.length > 0) {
              return true;
            }
            // check if the page group has product groups
            if (
              pageGroup.ProductGroups &&
              pageGroup.ProductGroups.length > 0
            ) {
              return true;
            }
            return false;
          }

          if (validateAdditionalProductAvailability) {
            for (const additionalProductPageGroup of additionalProductPageGroups) {
              if (additionalProductPageGroup.Products) {
                additionalProductPageGroup.Products =
                  additionalProductPageGroup.Products.filter(
                    isValidProduct
                  );
              }

              if (additionalProductPageGroup.ProductGroups) {
                for (const productGroup of additionalProductPageGroup.ProductGroups) {
                  productGroup.Products =
                    productGroup.Products.filter(isValidProduct);
                }

                additionalProductPageGroup.ProductGroups =
                  additionalProductPageGroup.ProductGroups.filter(
                    isValidProductGroup
                  );
              }
            }
          }

          additionalProductPageGroups =
            additionalProductPageGroups.filter(isValidPageGroup);

          // set the valid additional product page groups
            setAdditionalProductPageGroups(additionalProductPageGroups);

          // callback so that the page can redirect
          resolve(additionalProductPageGroups);
        });
      }
    });
  };

setAdditionalProductPageGroups = (additionalProductPageGroups) => {
  if (additionalProductPageGroups != null) {
    // set the next page groups
    for (
      let i = 0;
      i < additionalProductPageGroups.length;
      i++
    ) {
      const pageGroup = additionalProductPageGroups[i];
      if (additionalProductPageGroups.length > i + 1) {
        const nextPageGroup =
          additionalProductPageGroups[i + 1];
        pageGroup.NextPageGroup = nextPageGroup.Code
          ? nextPageGroup.Code
          : nextPageGroup.Name;
      } else {
        pageGroup.NextPageGroup = "";
      }
    }
    this.setState({
      additionalProductPageGroups: additionalProductPageGroups,
    });
    localStorage.setItem(
      "additionalProductPageGroups",
      JSON.stringify(additionalProductPageGroups)
    );
  }
  };

  updateAdditionalProductPageGroupsInitialQuantity = (
    bookingProducts
  ) => {
    const { additionalProductPageGroups } = this.state;
    let alteredGroups = additionalProductPageGroups;

    for (let a of alteredGroups) {
      for (let b of a.ProductGroups) {
        for (let c of b.Products) {
          for (let d of c.ProductVariants) {
            for (let e of bookingProducts) {
              if (
                d.VariantTypeName === e.VariantTypeName &&
                a.ParentProductId === e.ProductId
              ) {
                d.InitialQuantity = e.Quantity;
              }
            }
          }
        }
      }
    }
    this.setState({
      additionalProductPageGroups: alteredGroups,
    });
    localStorage.setItem(
      "additionalProductPageGroups",
      JSON.stringify(alteredGroups)
    );
  };

  clearDeliveryProductVariants = () => {
    this.setState({ deliveryProductVariants: null });
  };

  getNextPage = async (currentPage, callback, userLoggedIn) => {
    const { channel, booking } = this.state;

    const beneficiariesRedirectPages = [
      "additional",
      "booking",
      "shopping-basket",
      "memberships",
    ];
    const playerNamesRedirectPages =
      beneficiariesRedirectPages.concat(["beneficiaries"]);
    const donationRedirectPages = playerNamesRedirectPages.concat([
      "player-names",
    ]);
    const giftAidRedirectPages = donationRedirectPages.concat([
      "donation",
    ]);
    const deliveryRedirectPages = giftAidRedirectPages.concat([
      "gift-aid",
    ]);
    const bookingQuestionsRedirectPages =
      deliveryRedirectPages.concat(["delivery"]);

    const requiresDelivery =
      channel &&
      channel.SelectDeliveryAddress &&
      booking &&
      booking.RequiresDelivery
        ? true
        : false;

    const showDonationPage =
      channel &&
      channel.DonationProductVariantId &&
      booking &&
      booking.RoundUpAmount &&
      booking.RoundUpAmount > 0
        ? true
        : false;
    const showGiftAidPage = booking && booking.GiftAidValue > 0;

    if (beneficiariesRedirectPages.indexOf(currentPage) > -1) {
      this.beneficiariesRedirect(userLoggedIn, function (nextPage) {
        callback(nextPage);
      });
    } else if (
      RoutingService.getOrganisationCapturePlayerNamesToggle() &&
      playerNamesRedirectPages.indexOf(currentPage) > -1
    ) {
      this.playerNamesRedirect(userLoggedIn, function (nextPage) {
        callback(nextPage);
      });
    } else if (
      donationRedirectPages.indexOf(currentPage) > -1 &&
      showDonationPage
    ) {
      const donationPage = getUrlForPageComponent("Donation");
      callback(donationPage);
    } else if (
      giftAidRedirectPages.indexOf(currentPage) > -1 &&
      showGiftAidPage
    ) {
      this.giftAidRedirect(
        userLoggedIn ? userLoggedIn : false,
        function (nextPage) {
          callback(nextPage);
        }
      );
    } else if (
      deliveryRedirectPages.indexOf(currentPage) > -1 &&
      requiresDelivery
    ) {
      this.deliveryRedirect(userLoggedIn, function (nextPage) {
        callback(nextPage);
      });
    } else if (
      bookingQuestionsRedirectPages.indexOf(currentPage) > -1
    ) {
      this.bookingQuestionsRedirect(
        userLoggedIn,
        function (nextPage) {
          callback(nextPage);
        }
      );
    } else {
      const paymentPage = getUrlForPageComponent("Payment");
      callback(paymentPage);
    }
  };

  beneficiariesRedirect(userLoggedIn, callback) {
    const { booking } = this.state;
    const beneficiaryProducts =
      booking &&
      booking.BookingProducts.filter(
        (b) => b.Beneficiaries.length > 0
      );
    if (beneficiaryProducts && beneficiaryProducts.length > 0) {
      const beneficiariesPage =
        getUrlForPageComponent("Beneficiaries");
      callback(beneficiariesPage);
    } else {
      // Beneficiaries not required
      this.getNextPage("beneficiaries", callback, userLoggedIn);
    }
  }

  playerNamesRedirect(userLoggedIn, callback) {
    const { booking } = this.state;
    // Check for bowling products (Type = 6, 13 and 14) or for golf products (Type = 19)
    // in the booking products
    const products =
      booking &&
      booking.BookingProducts.filter(
        (b) =>
          b.Type === 6 ||
          b.Type === 13 ||
          b.Type === 14 ||
          b.Type === 19
      );
    if (products && products.length > 0) {
      const playerNamesPage = getUrlForPageComponent("PlayerNames");
      callback(playerNamesPage);
    } else {
      // Player names are not required
      this.getNextPage("player-names", callback, userLoggedIn);
    }
  }

  deliveryRedirect(userLoggedIn, callback) {
    TicketingService.readDeliveryProductVariants().then(
      (response) => {
        const postageOptions = response.data.PostageOptions;
        const nonPostageOptions = response.data.NonPostageOptions;

        // if only one delivery option and it is print at home, add it automatically
        if (
          postageOptions.length === 0 &&
          nonPostageOptions.length === 1 &&
          nonPostageOptions[0].PrintAtHome
        ) {
          let addProductRequests = [
            { Id: nonPostageOptions[0].Id, Quantity: 1 },
          ];
          addProductsToBooking(addProductRequests).then(
            (response) => {
              if (response) {
                this.setBooking(response.Booking);
                this.setBookingExpiry(response.BookingExpiry);

                this.getNextPage("delivery", callback, userLoggedIn);
              } else {
                // redirect to the delivery page
                this.setState({
                  deliveryProductVariants: response.data,
                });
                const deliveryPage =
                  getUrlForPageComponent("Delivery");
                callback(deliveryPage);
              }
            }
          );
        } else {
          // redirect to the delivery page
          this.setState({ deliveryProductVariants: response.data });
          const deliveryPage = getUrlForPageComponent("Delivery");
          callback(deliveryPage);
        }
      }
    );
  }

  giftAidRedirect(userLoggedIn, callback) {
    const giftAidPage = getUrlForPageComponent("GiftAid");
    if (userLoggedIn) {
      DonationManagementService.giftAidBooking().then((response) => {
        const result = response.data;
        if (
          result &&
          result.DonationProductCount > 0 &&
          result.DeclarationRequired
        ) {
          this.setState({ giftAidResult: result });
          callback(giftAidPage);
        } else {
          // Gift aid not required
          this.getNextPage("gift-aid", callback, userLoggedIn);
        }
      });
    } else {
      //If user is not logged in, then we don't know if they need gift aid page.
      //So we need to get the page that would be after gift aid so that in the event
      //that they don't need gift aid, we then redirect to the page after it.
      const redirect = (nextPage) => {
        const nextPages = {
          giftAid: giftAidPage,
          nextPage: nextPage,
        };

        callback(nextPages);
      };
      this.getNextPage(
        "gift-aid",
        (nextPage) => redirect(nextPage),
        userLoggedIn
      );
    }
  }

  bookingQuestionsRedirect(userLoggedIn, callback) {
    TicketingService.readBookingQuestions().then((response) => {
      const bookingQuestions = response.data;
      if (bookingQuestions && bookingQuestions.length > 0) {
        this.setState({ bookingQuestions: bookingQuestions });
        const bookingQuestionsPage = getUrlForPageComponent(
          "BookingQuestions"
        );
        callback(bookingQuestionsPage);
      } else {
        // no questions so redirect to the next page
        this.getNextPage("booking-questions", callback, userLoggedIn);
      }
    });
  }

  render() {
    const {
      additionalProductPageGroups,
      booking,
      bookingLoaded,
      bookingExpiry,
      bookingExpiryLoaded,
      bookingQuestions,
      channel,
      channelLoaded,
      deliveryProductVariants,
      giftAidResult,
    } = this.state;

    // the values that we will be providing the TicketingContext on this render
    const ticketingContext = {
      booking: booking,
      bookingLoaded: bookingLoaded,
      readBooking: this.readBooking,
      bookingExpiry: bookingExpiry,
      bookingExpiryLoaded: bookingExpiryLoaded,
      readBookingExpiry: this.readBookingExpiry,
      channel: channel,
      channelLoaded: channelLoaded,
      readChannel: this.readChannel,
      resetTicketingState: this.resetTicketingState,
      additionalProductPageGroups: additionalProductPageGroups,
      setBooking: this.setBooking,
      setBookingExpiry: this.setBookingExpiry,
      setBookingJourneyStartPage: this.setBookingJourneyStartPage,
      setAdditionalProductPageGroups:
        this.setAdditionalProductPageGroups,
      getValidAdditionalProductPageGroups:
        this.getValidAdditionalProductPageGroups,
      updateAdditionalProductPageGroupsInitialQuantity:
        this.updateAdditionalProductPageGroupsInitialQuantity,
      getNextPage: this.getNextPage,
      bookingQuestions: bookingQuestions,
      giftAidResult: giftAidResult,
      deliveryProductVariants: deliveryProductVariants,
      clearDeliveryProductVariants: this.clearDeliveryProductVariants,
    };

    return (
      <>
        <TicketingContext.Provider value={ticketingContext}>
          {this.props.children}
        </TicketingContext.Provider>
      </>
    );
  }
}
